/* global mecGlobal */
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import * as test from './test'
axios.defaults.headers.common = {"X-Requested-With": "XMLHttpRequest"};

export default {
	data: {
		baseUrl: '',// za8kladn� url pro naceni tabulky
		items: [],  //json ��dky tabulky
		filter: '', // zadan� data pro filtrov�n�
		filterBase: ''// odata $filter string
	},
	methods: {
		getUrl() {
			var url = this.baseUrl
			return url
		},
		//navigate(event, id) {
		//	event.preventDefault()
		//	var href = event.target.href || event.target.parentElement.href || event.target.parentElement.parentElement.href
		//	window.location = href + '/' + id
		//},
		///-- Pouzije href a doplni do nej id 
		navigate(event, item) {
			event.preventDefault()
			var href = event.target.href || event.target.parentElement.href || event.target.parentElement.parentElement.href
			href = href + '/' + item.id
			var that = this
			test.PopUpEvent(event
				, 'editace'
				, function () {
					that.reload(item)
				}
			)
		},
		///-- Pouzije href tak jak je . To je spr�vn� po 
		navigateX(event, item,href) {
			event.preventDefault()
			if (href == undefined) {
				href = event.target.href || event.target.parentElement.href || event.target.parentElement.parentElement.href;
			}
			var that = this
			test.PopUpEvent(event
				, ''
				, function () {
					that.reload(item)
				}
			)
		},
		AddNew(event,  href) {
			event.preventDefault()
			if (href == undefined) {
				href = event.target.href || event.target.parentElement.href || event.target.parentElement.parentElement.href;
			}
			var that = this
			test.PopUpEvent(event
				, ''//no caption add
				, function (item) {
					that.loadNew(item)
				}
			)
		},

		Delete(event, item, href) {
			event.preventDefault()
			if (href == undefined) {
				href = event.target.href || event.target.parentElement.href || event.target.parentElement.parentElement.href;
			}
			var that = this
			test.PopUpEvent(event
				, ''// no caption delete
				, function () {
					var index = that.items.indexOf(item);
					if (index > -1) {
						that.items.splice(index, 1);
					}
				}
			)
		},

		// parametr item je  id
		loadNew(item) {
			var that = this;
			var urlToReload = this.reloadUrl(item);
			if (urlToReload != undefined) {
				$.ajax({
					type: "GET",
					url: urlToReload,
					cache: false,
					data: { nohistory: true }
				}).done(function (response) {
					if (response.length == 1) {
						var newItem = response[0];
						that.items.unshift(newItem);
						return -1;

					}
				});
			}
		},

		reload(item) {
			var urlToReload = this.reloadUrl(item);
			if (urlToReload != undefined) {
				$.ajax({
					type: "GET",
					url: urlToReload,
					cache: false,
					data: { nohistory: true }
				}).done(function (response) {
					if (response.length == 1) {
						var newItem = response[0];
						for (var prop in newItem) {
							if (item.hasOwnProperty(prop)) {
								item[prop] = newItem[prop];
							}
						}
					}
				});
			}
		},
		reloadUrl(item) {
			var url = this.getUrl()
			if (url.indexOf('?') == -1) {
				url += '?'
			}
			else {
				url += '&'
			}
			if (item == undefined || item === null) {
				return undefined;
			} else if (Number.isInteger(item)) {
				url += "$filter=Id eq " + item
			} else if (typeof item === 'object' && item.id != undefined){
				url += "$filter=Id eq " + item.id
			}
			return url;
			//return '/api/pricelistdata/reload?id=' + item.id
		},
		/// spusti refresh za 750 ms necinnosti  po zmene
		filterChange: _.debounce(function (e) {
			this.refresh()
		}, 750),

		onResponse(response)
		{
		this.items = response.data
		},
		// zavolat tuto funkci
		orderBy(name) {
			if (this.order == undefined) {
				this.order = name;
			}
			else if (name == this.order) {
				this.order = name + ' desc';
			} else {
				this.order = name;
			}
			this.refresh();
		}
		,
		refresh() {
			var that = this

			var filterNumbers = [];
			var filterStrings = [];
			var filterDates = [];
			var filterDateRange = [];

			//-- filter strings
			if (this.filter != '') {
				if (this.filter.toLowerCase() == this.filter)
				{  // retezec zadan malymi pismeny .. case insenssitive
					filterStrings = _.map(mecGlobal.stringProperties, p => 'contains(tolower(' + p + '),\'' + _.trim(that.filter.toLowerCase()) + '\')' );
				}
				else
				{   
					filterStrings = _.map(mecGlobal.stringProperties, p=> 'contains(' + p + ',\'' + _.trim(that.filter) + '\')' );
				}
			}
			//-- filter numbers
			if (this.filter != '' && !isNaN(+this.filter))
				filterNumbers = _.map(mecGlobal.numberProperties, function (n) {
					return n + ' eq ' + _.trim(that.filter)
				})
			//-- filter datetime
			if (this.filter != '') {
				var split = that.filter.split(' ')

				console.log(split)

				var date = moment(split[0], 'L', 'cs', true).isValid() ? moment(split[0], 'L', 'cs', true) : moment(split[1], 'L', 'cs', true)
				var time = moment(split[0], 'LTS', 'cs', true).isValid() ? moment(split[0], 'LTS', 'cs', true) : moment(split[1], 'LTS', 'cs', true)

				console.log(date, time)

				if (date.isValid() || time.isValid()) {
					var timeFilter;

					if (date.isValid() && !time.isValid()) {
						timeFilter = date
						filterDates = _.map(mecGlobal.dateProperties, function (n) {
							return n + ' gt ' + timeFilter.locale(document.documentElement.lang).add(-1, 'd').toISOString() + ' and ' + n + ' lt ' + timeFilter.locale(document.documentElement.lang).add(2, 'd').toISOString()
						})
					} else {
						if (date.isValid() && time.isValid()) {
							timeFilter = date.add(time.hours(), 'h').add(time.minutes(), 'm').add(time.seconds(), 's').add(time.milliseconds(), 'ms')
						}
						if (time.isValid()) {
							var today = moment().locale(document.documentElement.lang).hour(0).minute(0).second(0).millisecond(0)
							timeFilter = today.add(time.hours(), 'h').add(time.minutes(), 'm').add(time.seconds(), 's').add(time.milliseconds(), 'ms')
						}

						filterDates = _.map(mecGlobal.dateProperties, function (n) {
							return n + ' ge ' + timeFilter.locale(document.documentElement.lang).add(-2, 'm').toISOString() + ' and ' + n + ' le ' + timeFilter.locale(document.documentElement.lang).add(4, 'm').toISOString()
						})
					}
				}
			}
			
			if ((this.hasOwnProperty('dateFrom') && this.dateFrom != '')
				&& (this.hasOwnProperty('dateTo') && this.dateTo != '')
			) {
				if (mecGlobal.dateProperties != undefined) {// viz detail. energymeter . MecGlobal
					filterDateRange = _.map(
						mecGlobal.dateProperties,
						function (n) {
							return n + ' ge ' + moment(that.dateFrom, 'L', 'cs', true).locale(document.documentElement.lang).add(-2, 'm').toISOString() + ' and ' +
								n + ' le ' + moment(that.dateTo, 'L', 'cs', true).locale(document.documentElement.lang).add(-2, 'm').toISOString()
						}
					);
				}
			}

			//var filter = _.union(filterStrings, filterNumbers, filterDates, filterDateRange).join(' or ')
			var filter = test.JoinConditions("or",_.union(filterStrings, filterNumbers, filterDates, filterDateRange))

			//-- ziskej hodnotu z filterBase  (funkce, string)
			var filterBaseResult;
			if (this.filterBase instanceof Function) { filterBaseResult = this.filterBase()}//function
			else { filterBaseResult = this.filterBase } // string
			filterBaseResult = String(filterBaseResult);

			filter = test.JoinConditions("and", [filter,filterBaseResult])

			////filterBaseResult = filterBaseResult.tos
			//if (filterBaseResult != '' && filter!='' ) {
			//	filter = '(' + filterBaseResult + ') and (' + filter + ')';
			//}
			//else if (filterBaseResult != '') {
			//	filter = filterBaseResult;
			//} 

			var odataString = ''
			if (filter.length > 0) {
				odataString += '$filter=' + _.trim(filter);
			}
			;
			//-- razeni
			if (this.order == undefined);
			else if (filter.length > 0 && this.order.length > 0)
				odataString += '&$orderby=' + _.trim(this.order)
			else if (filter.length == 0 && this.order.length > 0)
				odataString += '$orderby=' + _.trim(this.order)

			var that = this
			var url = this.getUrl()
			if (odataString.length > 0) {
				if (url.indexOf('?') == -1)
				{
					url += '?'
				}
				else
				{
					url += '&'
				}
				url += odataString
			}
			axios.get(url)
				.then(response => {
					that.onResponse(response)
				})
				.catch(test.OnAjaxError);
		}
		,
		JoinConditions(operator, conditions) { return test.JoinConditions(operator, conditions); }
		,
		Condition(column, operator, value) { return test.Condition(column, operator, value); }
	}
}