import baseForm from './baseForm'
import Vue from 'vue'
//import axios from 'axios'
import _ from 'lodash'

var forms = document.querySelectorAll('form')
for (var index = 0; index < forms.length; index++) {
	var element = forms[index]
	console.log(element)
	if (element.value != "VueSpecific") {
		new Vue({
			el: element,
			extends: baseForm
		})
	}
}