import axios from 'axios'
import _ from 'lodash'
import * as test from './test'
axios.defaults.headers.common = { "X-Requested-With": "XMLHttpRequest" };


export default {
	data: {
		modelState: {}
	},
	mounted() {
		console.log('Form Mounted')
	},
	methods: {
		submitForm(event) {
			var that = this
			var form = event.target
			axios.post(window.location, new FormData(form))
				.then(function (response) {
					console.log(response)
					if (typeof window.DefinityOnAccept === "function") {
						window.DefinityOnAccept(response.data);
					}
					if (response.request.responseURL) {
						console.log("redirect")
						window.location = response.request.responseURL
					}
				})
				.catch(function (error) {
					if (error.response.status == 400) {
						that.modelState = error.response.data
					}
					else
					{
						test.OnAjaxError(error);
					}
				})
		}
	}
}