import baseTable from './baseTable'
import Vue from 'vue'
import base from './baseTable'
import _ from 'lodash'


//import base from 'base';
//import moment from 'moment';

//import Chart from 'chart.js'
//import chartApi from './chartsapi'


export function NewVue(options) {

	return new Vue(options);
}

export function mecGlobal() {
	return mecGlobal;
}

//table
export function kendoBaseTable(options) {
	options.extends = baseTable;
	//return new Vue(options);
	return options;
}
// event pripojen pres vue
// title,onclose nepovinne
export function PopUpEvent(event,title, OnClose) {
	event.preventDefault();
	var href = event.target.href || event.target.parentElement.href || event.target.parentElement.parentElement.href;
	PopupUrl(href, title,OnClose);
}
// event pripojen pres <element onclick="return OnClickA(this);"
// title,onclose nepovinne
export function PopUpElement(element, title, OnClose) {
	var href = element.href || element.parentElement.href || element.parentElement.parentElement.href;
	PopupUrl(href, title, OnClose);
	return false;
}

export function PopupUrl(href, title, OnClose)
{
	var promise = $.Deferred();
	href = urlAddNomenu(href)
	//iframe
	var windowOptions = {
		...WindowModalOptions(href, title),
		...{
			close: function (e) {
				// zavreni krizkem
				//debugger;
				let result =
				{
					cancel: e.userTriggered,
					submitResult: window.DefinityData, // informace o ulo�en�
					//selectedItem:
				};
				console.log("CloseWindowEvent");
				console.log(result);
				popup.destroy();
				promise.resolve(result); // okno jednou zavru
			}
		}
	};
	var popup = $('<div class="window" />').kendoWindow(windowOptions).data("kendoWindow");

	popup.center();//
	popup.open();

	var iframeWindowObject = popup.element.children("iframe")[0].contentWindow;
	iframeWindowObject.DefinityOnAccept = function (e)
	{
		//mozna uzivatelsk� akce, l�pe vyuzij promise
		if (typeof OnClose === "function") {
			OnClose(e);
		}
		let result =
		{
			cancel: false,
			submitResult:e// window.DefinityData, // informace o ulo�en�
		};
		console.log("DefinityOnAccept.CloseWindow");
		console.log(result);
		popup.destroy();// zavre okno 
		promise.resolve(result); 
	}
	popup.center();//
	popup.maximize();//
	return promise;
}

// zavre popup a vrati aktualni radek KendoGrid 
export function KendoSelectGridItem(gridId) {
	var xgrid = $("#" + gridId).data("kendoGrid");
	var selectedItem = xgrid.dataItem(xgrid.select());
	if (typeof window.DefinityOnAccept === "function") {
		window.DefinityOnAccept(selectedItem);
	}
	return;
}


// modal window enabled
export function VueForm(element,options) {
	return VueOptionsMerge(
		options,
		{
			el: element,
			data: {
				modelState: {}
			},
			mounted() {
				console.log('Form Mounted')
			},
			methods:
			{
				submitDone(response) {
					console.log("submitedOK");
					// je li spusteno v okn�, spusti akce volaj�c� strany
					// standartn� zav�en� okna
					//  aktualizace zaznamu , viz OnClose
					if (typeof window.DefinityOnAccept === "function") {
						window.DefinityData= response.data;
						window.DefinityOnAccept(response.data);
					}
					// je li responseUrl, p�ejde tam 
					if (response.request) {
						if (response.request.responseURL) {
							window.location = response.request.responseURL
						}
					}
				},
				submitFailed(error) {
					if (error.statusCode().status == 400) {
						that.modelState = error.response.data
					}
				},
				submitForm(event)
				{
					AjaxPostFormData(event.target)
						.done(this.submitDone)
						.fail(this.submitFailed)
				},
			}
		}
	)
}


export function VueFormJson(element,options) {
	//debugger
	var x = VueOptionsMerge(
		options,
		VueForm(element,
			{
				methods: {
					submitForm(event) {
						AjaxPostJson('/api/pricelistdataS/Update', this.modelData)
							.done(this.submitDone)
							.fail(this.submitFailed)
					}
				}
			}
		)
	)
	return x;
}

export function urlAddNomenu(href)
{
	if (href.includes('?')) { href = href + "&NOMENU" }
	else { href = href + "?NOMENU" }
	return href
}

export function WindowModalOptions(href,caption) {
	return {
		content: href,
		iframe: true,
		actions: ["Custom", "Minimize", "Maximize", "Close"],
		draggable: true,
		resizable: true,
		top: "20px",
		width: "90%",
		height: "700px",
		title: caption,
		modal: true,
		close: function (e) {
			that.reload(item)
			popup.destroy();
		}
	};
}

export function VueOptionsMerge(options,def)
{
	let result = { ...def, ...options };
	result.data = { ...def.data, ...result.data }
	result.methods = { ...def.methods, ...result.methods }
	return result
}
export function AjaxGetJson(url) {

	var result= $.ajax({
		type: "GET",
		headers: {
			'Content-Type': 'application/json', // vstup
			'Accept': 'application/json'		// vystup
		},
		url: url,
		cache: false,
		//dataType    : 'json',// datatype expected from server
		processData: false, // data budou prevedena na urlencoded
		contentType: false,
	})
	result.fail(OnAjaxError);
	return result;
}

export function AjaxPostJson(url,data) {
	var result = $.ajax({
		type: "POST",
		headers: {
			'Content-Type': 'application/json', // vstup
			'Accept': 'application/json'		// vystup
		},
		url: url,
		cache: false,
		//dataType    : 'json',// datatype expected from server
		processData: false, // data budou prevedena na urlencoded
		contentType: false,
		data: JSON.stringify(data)
	});
	result.fail(OnAjaxError);
	return result;
}
export function AjaxPostFormData(form) {
	var formdata = new FormData(form);
	var result= $.ajax({
		type: "POST",
		url: window.location,
		cache: false,
		processData: false,
		contentType: false,
		data: formdata
	})
	result.fail(OnAjaxError);
	return result;
}

export function OnAjaxError (err) {
	//console.log(error.response.status) // 401
	//console.log(err.response.data.error) //Please Authenticate or whatever returned from server
	var status = 200;
	var message = "error";
	if (err.status != undefined) {//ajax
		status = err.status;
		message = err.state() + err.responseText;// "rejected"
	}
	else if (err.response != undefined) {//axios
		status = err.response.status;
		message = err.message;
	}
	if (status == 401) {
		//alert("not logged");
		PopupUrl('/Identity/Account/Login', localization.loginExpired)
			.done(
				function (result) {
					//debugger
					//if (!result.cancel) {
					//	alert("logged");
					//}
				}
			)
			//.fail(this.submitFailed)
	}
	else  {
		alert(message);
	} 
}

//operator and , or
// condition string
export function JoinConditions(operator, conditions) {
	var xarray;
	//-- hodnota nebo pole=>pole
	if (Array.isArray(conditions)) xarray = conditions;
	else xarray = [conditions];
	//-- zrus prazdn� polozky
	var x = [];
	xarray.forEach(
		(value) => {
			if (value != undefined && value != '') x.push(value);
		}
	);
	//--
	if (x.length > 1) return '(' + x.join(' ' + operator + ' ') + ')';
	else if (x.length > 0) return x.join(' ' + operator + ' ');
	else return '';
}

export function Condition(column, operator, value)
{
	if (value == undefined || value == '')
		return '';
	else
		return column.concat(' ', operator, ' ', value);
}



